import { isNil } from 'lodash';

const Formatter = {
  amount(value, digit = 2, sign = '$') {
    const val = parseFloat(value);
    if (value != null && Number.isNaN(val) === false) {
      const newValue = val.toFixed(digit).replace(/,/g, '');
      const parts = newValue.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return val < 0
        ? `-${sign}${parts.join('.').replace(/-/, '')}`
        : sign + parts.join('.');
    }
    return 'N/A';
  },

  amtRounded(v) {
    return Formatter.amount(v, 0);
  },

  percentage(value, times = 100, decimal = 1) {
    if (value != null && Number.isNaN(parseFloat(value)) === false) {
      return `${(value * times).toFixed(decimal)}%`;
    }
    return 'N/A';
  },

  pct(value) {
    return Formatter.percentage(value, 1);
  },

  date(value) {
    let dateArray;
    if (value != null) {
      if (value.length > 10) {
        const strippedValue = value.split('T')[0];
        dateArray = strippedValue.split('-');
        return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
      }
      dateArray = value.split('-');
      return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
    }
    return null;
  },

  phoneNumber(value) {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  },

  days(date1, date2) {
    if (date1 != null && date2 != null) {
      return Math.floor(
        (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
          Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())) /
          (1000 * 60 * 60 * 24),
      );
    }
    return 'N/A';
  },
};

/** takes a dollar string ($5,000.00) and returns number (5000) */
export const dollarsToNumber = dollars => {
  const number = dollars.replace(/[$,]/g, '');
  return parseInt(number, 10);
};

export const timeStampToDate = str => {
  let day = null;
  let month = null;
  let year = null;

  if (str.includes('T')) {
    const localDate = new Date(str);
    day = ('0' + localDate.getDate()).slice(-2);
    month = ('0' + (localDate.getMonth() + 1)).slice(-2);
    year = ('' + localDate.getFullYear()).slice(-2);
  } else {
    const strippedValue = str.split('T')[0];
    const dateArr = strippedValue.split('-');
    day = dateArr[2];
    month = dateArr[1];
    year = dateArr[0].substr(2, 2);
  }

  return `${month}/${day}/${year}`;
};

/** takes number(5000) and returns dollars($5,000.00) */
export const numberToDollar = number =>
  number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
export const parseDollars = val => val && Number(val.replace(/[^0-9.-]+/g, ''));
export const addDollarCommas = val => parseDollars(val).toLocaleString();

export const toTitleCase = str => {
  if (isNil(str)) return '';

  return str
    .toString()
    .split('_')
    .map(item => item.charAt(0).toUpperCase() + item.substring(1))
    .join(' ');
};

export const formatDate = str => {
  const dateArr = str.split('-');
  return `${dateArr[1]}/${dateArr[2]}/${dateArr[0].substr(2, 2)}`;
};

export const formatPhoneNumber = str =>
  str.substring(1).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const normalizePhone = value => {
  if (!value) return '1-';

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 4)
    return `${onlyNums.slice(0, 1)}-${onlyNums.slice(1)}`;

  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 1)}-${onlyNums.slice(1, 4)}-${onlyNums.slice(
      4,
    )}`;
  }

  return `${onlyNums.slice(0, 1)}-${onlyNums.slice(1, 4)}-${onlyNums.slice(
    4,
    7,
  )}-${onlyNums.slice(7, 11)}`;
};

export const pad = n => (n < 10 ? `0${n}` : n);

export default Formatter;
